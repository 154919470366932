<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<!-- <v-row align="center" justify="space-between">
					
					<v-spacer></v-spacer>
                    <v-col cols="12" lg="6" md="6" xl="5">
                        <v-row justify="end">
                            
                            
                           
                        </v-row>
                    </v-col>
                   
				</v-row> -->
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler" class="table-employess">
							
						</datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>



        <!-- VACATION REQUEST -->
        <modal :props="modalRequestVacationProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
				
                <v-row class="mt-1">
                    <v-col class="px-0">
                        
                        <p class="pb-0">
                            LA/EL EMPLEADO/A <span class="font-weight-bold">{{requestName}}</span> HA SOLICITADO UN PERIODO
                            VACACIONAL DE <span class="font-weight-bold">{{requestDays}} {{requestDays == 1 || requestDays == "1" ? 'día' : 'DÍAS'}}</span>  EN LAS FECHAS COMPRENDIDAS ENTRE
                            EL <span class="font-weight-bold">{{requestStart}}</span> Y <span class="font-weight-bold">{{requestEnd}}</span> SI LA SOLICITUD ES PROCEDENTE
                            MARCA LA CASILLA DE VERIFICACIÓN Y FINALIZA CON EL BOTÓN
                            AUTORIZAR, DE LO CONTRARIO SÓLO MARCA NO AUTORIZAR
                        </p>

                        <checkboxForm
                            label="AUTORIZO EL PERIODO VACACIONAL SOLICITADO"
                            color="success"
                            :valueInput="requestChecked"
                            :rule="[]"
                            @valueChange="(v) => {requestChecked = v; }"
                        ></checkboxForm>

                    </v-col>
                </v-row>
                
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" >
					<v-col cols="12" sm="4" class="">
						<secondaryButton
                            :props="cancelRequestVacationModal"
						></secondaryButton>
					</v-col>
                    
					<v-col cols="12" sm="4"  class="mr-sm-2">
						<primaryButton
						:props="acceptRequestVacationModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        


      

       
		
	</div>
</template>
<script>
import NotificationStorage from '../../../../helpers/NotificationStorage';
// import ChipDataTableItem from "../../../components/ChipDataTableItem.vue";
import Utils from '../../../../helpers/Utils';
export default {
	components: {
    //    'chip-item': ChipDataTableItem
    },
    data(){
		return {
            exportBusinessSelected: "ALL",
            exportStatusSelected: "ACTIVO",

            requestName: "",
            requestDays: "",
            requestStart: "",
            requestEnd: "",
            requestBody: {},
            requestChecked: false,
            requestId: "",

			actions: [
                {
					name: 'Vacaciones',
					icon: {
						color: 'secondary',
						icon: 'mdi-umbrella-beach-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/estadisticas',
                    permissions: 'vacations.create',
                    activeModal: false,
				},
                {
					name: 'Estadísticas',
					icon: {
						color: 'secondary',
						icon: 'mdi-chart-box-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/estadisticas',
                    permissions: 'employees.show'
				},
               
                // {
				// 	name: 'Nueva Eventualidad',
				// 	icon: {
				// 		color: 'secondary',
				// 		icon: 'mdi-text-box-plus-outline'
				// 	},
				// 	disabled: false,
				// 	type: 'redirect',   //  method, external, redirect
				// 	action: '/empleados/1/estadisticas',
                //     permissions: 'employees.create'
				// },
				
			],
			tableProps: {
				headers: [
                    {
                        text: 'Empleado',
                        align: 'left small-th-employees',
                        value: 'employe_number',
                        class: 'table-b-border white-space-normal small-th-employees'
                    },
                    {
                        text: 'Nombre',
                        align: 'left small-th-employees',
                        value: 'name',
                        class: 'table-b-border white-space-normal small-th-employees'
                    },
                     {
                        text: 'Puesto',
                        align: 'center small-th-employees',
                        value: 'position',
                        class: 'table-b-border white-space-normal'
                    },
                   
                    
                    {
                        text: 'Ingreso',
                        align: 'center small-th-employees',
                        value: 'date_i',
                        class: 'table-b-border white-space-normal'
                    },

                    {
                        text: 'Cumpleaños',
                        align: 'center small-th-employees',
                        value: 'birthdate',
                        class: 'table-b-border white-space-normal'
                    },
                    {
                        text: 'Vacaciones Pend',
                        align: 'center small-th-employees',
                        value: 'vacation_days_pending',
                        class: 'table-b-border white-space-normal'
                    },
                   
                    //{
                        //  text: 'Estatus',
                        // align: 'left small-text-8',
                        // value: 'status',
                        //class: 'table-b-border'
                    //},
                    {
                        text: 'Acciones',
                        align: 'right',
                        sortable: false,
                        value: 'actions',
                        class: 'table-b-border'
                    }
				],
				items: [],
				search: '',
				loading: false
			},

          
			
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			},
           

            modalRequestVacationProps: {
                visible: false,
				width: '600',
				title: 'Solicitud de vacaciones',
				text: '',
				loading: false,
				bottomLineColor: 'success',
            },

           
            cancelRequestVacationModal: {
                text: "No Autorizar",
                classes: "",
                icon: "",
				to: "",
				block: true,
				color: '',
                loading: false,
                disabled:false,
                click: ()=>{this.rejectVacations()}
            },
			
			acceptRequestVacationModal: {
                text: "Autorizar",
                icon: "",
				to: "",
				block: true,
				color: 'success',
				textColor:'white',
                textColorLevel:"0",
				loading:false,
                disabled:true,
                click: ()=>{this.acceptVacations()}
            },
			
		}
	},
    watch: {
        /**
         * Reset values on "type" change
         */
        [`requestChecked`]: function () {
            this.acceptRequestVacationModal.disabled = true;
            if(this.requestChecked){
                this.acceptRequestVacationModal.disabled = false;
            }
        },
       
        [`confirmNameInput`]: function () {
            if(this.confirmNameRef.toUpperCase() == this.confirmNameInput.toUpperCase()){
                this.acceptDeleteModal.disabled = false;
            // console.log(this.confirmNameRef, this.confirmNameInput, this.acceptDeleteModal.disabled)
                return;
            }
            this.acceptDeleteModal.disabled = true;
            // console.log(this.confirmNameRef, this.confirmNameInput, this.acceptDeleteModal.disabled)
        },
     
    },
    computed: {
       
    },

	mounted(){
        this.setNotificationItem();
        this.index();
		//this.index()
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        setNotificationItem(){
            this.requestId = NotificationStorage.get()?.idRecord??"";
            // console.log(this.requestId, "id id")
            NotificationStorage.remove();
            
        },
        openByNotification(){
            if(this.requestId != undefined && this.requestId != null && this.requestId != ""){
                const found = this.tableProps.items.find((item) => item.current_vacation?.id == this.requestId && item.current_vacation?.status == "pendiente");
                // console.log(found, "encontrado");
                if(found != undefined){
                    this.checkVacationRequest(found.parameters);
                }
            }
        },
        
       
		index() {
			this.tableProps.loading = true;
            this.tableProps.items = [];
			//call api
			this.$api.employees.indexByBusiness(this.$store.state.company, this.selectedOffice, this.selectedStatus)
				.then((resp) =>{
					// console.log(resp, "employees index");
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))

                        x.actions[0].action = { name: 'EmployeeVacationsCreate', params: { id: x.id } }
                        //check if employee has pending vacations
                        if(x?.current_vacation != null){
                            if(x.current_vacation.status == "pendiente"){
                                x.actions[0].icon.color = "red";
                                x.actions[0].activeModal = true;
                                x.actions[0].type = 'method'; 
                                x.actions[0].action = 'checkVacationRequest';
                                // const days = Utils.datediff(Utils.parseDate(x.current_vacation.start_date), Utils.parseDate(x.current_vacation.end_date));
                                x['parameters'] = {idVacation: x.current_vacation.id, start_date: x.current_vacation.start_date, end_date: x.current_vacation.end_date, days: (x.current_vacation.start_year_quantity + x.current_vacation.end_year_quantity ) }
                            }
                        }
						
						// x.actions[1].action = { name: 'EmployeeEdit', params: { id: x.id } }
						x.actions[1].action = { name: 'EmployeeStatistic', params: { id: x.id } }
                        // x.actions[4].action = { name: 'EmployeeEventCreate', params: { id: x.id } }
						
						
						x['parameters'] = {...x['parameters'], id: x.id, name: x.name};
						x.position = x.current_contract?.position?.name??'';
						x.date_i = Utils.globalDateFormat(x.current_contract?.admission_date);
                        x.office = x.current_contract?.branch_office?.name??'';
                        x.birthdate = Utils.globalDateFormat(x.birthdate??"");
                        x.vacation_days_pending = `${x?.vacation_days_pending??0} días`;

						// x.status = this.getStatus(x.current_contract.type, x.current_contract.start_date, x.current_contract.end_date);
						
						return x
					});
                    this.openByNotification();
				})
				.catch((error) =>{
					console.log(error, "error employees index");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
		},


        checkVacationRequest(params)
        {
            console.log(params);
            this.requestChecked = false;
            this.requestName = params.name;
            this.requestDays = params.days;
            this.requestId = params.idVacation;
            this.requestStart = Utils.globalDateFormat(params.start_date);
            this.requestEnd = Utils.globalDateFormat(params.end_date);
            this.requestBody = {
                start_date: params.start_date,
                end_date: params.end_date,
                employee_id: params.id,
            };
            this.modalRequestVacationProps.visible = true;
            
        },


        rejectVacations(){
            this.cancelRequestVacationModal.loading = true;
           
			this.$api.vacation.rejectRequest(this.requestId, this.requestBody)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha rechazado la solicitud`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalRequestVacationProps.visible = false;
				this.cancelRequestVacationModal.loading = false;
			})
        },

        acceptVacations(){
            this.acceptRequestVacationModal.loading = true;
           
			this.$api.vacation.authorizeRequest(this.requestId, this.requestBody)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha autorizado la solicitud`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalRequestVacationProps.visible = false;
				this.acceptRequestVacationModal.loading = false;
			})
        }

       
         

    }
}
</script>

<style lang="scss">

    .container-input-share{
        border: 1px solid gray;
    }

    .outside{
        margin-top: -7px !important;
    }

    .justi-center{
        justify-content: center;
    }

    .width-fit{
        max-width: fit-content;
    
    }

    .chip-employees-index{
        min-width: 32px;
        justify-content: center;
    }

  
    
    // @media (max-width: $display-breakpoints-xl)  {
    //     .width-fit{
    //         max-width: fit-content !important;
    
    //     }
    // }

    // @media (max-width: $display-breakpoints-sm)  {
    //     .width-fit{
    //         max-width: unset;
    
    //     }
    // }


    
</style>