<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col>
                        <autocompleteOneForm
                            label="Empresa"
                            :valueInput="values.company"
                            :rule="rules.company"
                            :items="serverItems.company"
                            @valueChange="(v) => {values.company = v; getEmployeeByBusiness(v); delete serverErrors['company']}"
                            :loading="loadingCompanies"
                            :errorMessages="serverErrors['company']"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                       <autocompleteOneForm
                            label="Empleado"
                            :valueInput="values.employee_id"
                            :rule="rules.employee_id"
                            :items="serverItems.employees"
                            @valueChange="(v) => {values.employee_id = v; delete serverErrors['employee_id']}"
                            :loading="loadingEmployee"
                            :errorMessages="serverErrors['employee_id']"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
               
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            tab: null,
            show4: false,
            loadingCompanies: false,
            loadingEmployee:false,
            
            rules: {
				employee_id: [
					//v => !!v || 'el campo es requerido'
				],
				company: [
                    //v => !!v || 'El campo es requerido'
				]
				
            },
            serverItems: {
                company: [],
                employees: [{text:"Sin asignar", value: null}],
            },

          
        }
    },
    mounted(){
        this.getCompanies();
        this.getEmployeeByBusiness(this.values.company)
    },
    methods: {
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
					this.serverItems.company = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
				})
				.catch((error) =>{
					console.log(error, "error desde form areas");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        },

        getEmployeeByBusiness(business = undefined)
        {
            if(business == '' || business == undefined)
                return;
            this.loadingEmployee = true;
            this.$api.employees.indexByBusiness(business)
                .then((resp) => {
                    // this.serverItems.employees = [];
                    
                    this.serverItems.employees = resp.data.map((emp) =>{
                        return {value:emp.id, text:emp.name}
                    });
                    this.serverItems.employees.push({text:"Sin asignar", value: ""});
                })
                .catch((error) => {
                    console.log(error, "assing form");
                })
                .finally(() =>{
                    this.loadingEmployee = false;
                })
            ;
        }
    }
}
</script>

<style>

</style>